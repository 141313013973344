window.zeppelin ?= {}

elByTag = window.zeppelin.elByTag
elById = window.zeppelin.elById
elByClass = window.zeppelin.elByClass
elByQuery = window.zeppelin.elByQuery
observe = window.zeppelin.observe
observeMulti = window.zeppelin.observeMulti
newEl = window.zeppelin.newEl
doAjax = window.zeppelin.doAjax

# ----------------

feature_video = ->
    videoarea = elById("videoarea")
    main_image = elById("main_image")
    featured_video = elByClass("featured-video")?.item(0)
    if videoarea and featured_video
        videoarea.appendChild(featured_video)
        main_image.remove()

observe(document, "DOMContentLoaded", (event) ->
    feature_video()
)
