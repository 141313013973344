window.zeppelin ?= {}

debounce = window.zeppelin.elByTag
elByTag = window.zeppelin.elByTag
elById = window.zeppelin.elById
elByClass = window.zeppelin.elByClass
elByQuery = window.zeppelin.elByQuery
observe = window.zeppelin.observe
observeMulti = window.zeppelin.observeMulti
newEl = window.zeppelin.newEl
doAjax = window.zeppelin.doAjax

# ----------------

htmlContainer = undefined
json_output = undefined

valid_product_link = /\?g=\d/
valid_provider_link = /\?provider=\d/
valid_mailto = /\mailto:.*/
valid_url = /^(http|https):\/\/.*/
unspaced_nor_tel = /\d{8,}/
plain_nor_tel = /^(?:\d[ ]*){8,}$/
prefixed_nor_tel = /^\+47(?:\d[ ]*){8,}$/
tel_with_prefix = /^(\+|0){1,}[\d\- ]+$/

known_bad_urls = {
  "www.merckmillipore.com": "http://www.merckmillipore.com/",
  "http//:www.montebello.no": "https://www.montebello.no/",
}

addSpaces = (numberstring) ->
  if numberstring.match(unspaced_nor_tel)
    spaced = numberstring.replace(/(\d{2})/g, '$1 ').replace(/(^\s+|\s+$)/,'')
    console.log(numberstring, " -> ", spaced, " (added spaces)")
    return spaced
  console.log(numberstring, " (already had spaces)")
  return numberstring

handle_known_bad_values = (mapping, td, type, value) ->
  switch type
    when 'homepage'
      if known_bad_urls.hasOwnProperty(value)
        goodhref = known_bad_urls[value]
        mapping.homepage = goodhref
        console.info("Mapped known bad homepage link ", value, " to ", goodhref)
        return true
    when 'fax', 'tel'
      if value is 'lisa.russell@abbott.com'
        mapping.email = value
        console.info("Mapped email ", value, " stored as fax to email field")
        return true
      if value is '46-85520329'
        mapping.fax = '+46 8 555 20 329'
        console.info("Mapped known bad fax", value, " to ", mapping.fax)
        return true

  return false


# ----------------------------------------

product_parse = (html) ->
  productlinks = html.getElementsByTagName("a")
  resultArr = []
  for plink in productlinks
    href = plink.getAttribute('href')
    if href.match(valid_product_link)
      spl = href.split('=')
      pk = parseInt(spl[1], 10)
      resultArr.push({
        "model": "products.product",
        "pk": pk,
        "fields": { "title_en": "", "title_nb": plink.innerHTML, "active": true }
      })
    else
      console.warn("Bad link:", plink)
  resultArr.sort((a,b) -> a.pk - b.pk)


  return resultArr

# ----------------------------------------

makeParseLine = (key) ->
  return (mapping, td) ->
    mapping[key] = td.innerHTML

makePhoneParseline = (key) ->
  return (mapping, td) ->
    phoneNo = td.innerHTML.trim()
    if phoneNo.match(plain_nor_tel) or phoneNo.match(prefixed_nor_tel)
      # console.info(key, " : '#{phoneNo}' (8 digits no prefix, assumed to be Norwegian +47)")
      mapping[key] = addSpaces(phoneNo)
    else if phoneNo.match(tel_with_prefix)
      mapping[key] = phoneNo
      console.info(key, " : '#{phoneNo}' (prefixed number assumed to be special or foreign)")
    else if handle_known_bad_values(mapping, td, key, phoneNo)
      # handled
    else
      console.warn(key, " : '#{phoneNo}' is invalid")
      mapping[key] = phoneNo


emailParseline = (mapping, td) ->
  mailtolink = td.getElementsByTagName("a").item(0)
  href = mailtolink.getAttribute("href")
  if href.match(valid_mailto)
    mapping.email = href.split(':')[1]
  else
    console.warn("Invalid email link:", td)

homepageParseline = (mapping, td) ->
  homepagelink = td.getElementsByTagName("a").item(0)
  href = homepagelink.getAttribute("href").trim()
  if href.match(valid_url)
    mapping.homepage = href
  else if handle_known_bad_values(mapping, td, 'homepage', href)
    # handled
  else
    mapping.homepage = href
    console.warn("Invalid homepage link:", href)

name_mapping = {
  "Tittel": makeParseLine("name"),
  "Adresse": makeParseLine("address"),
  "Postnummer": makeParseLine("postnummer"),
  "Sted": makeParseLine("poststed"),
  "Land": makeParseLine("country"),
  "Telefon": makePhoneParseline("tel"),
  "Fax": makePhoneParseline("fax"),
  "Beskrivelse": makeParseLine("description_nb"),
  "E-post": emailParseline,
  "Hjemmeside": homepageParseline,
}

singleprovider_parse = (html, thisProviderPk) ->
  console.info("Parsing ", thisProviderPk)
  tables = html.getElementsByTagName("table")

  datarows = tables.item(0).getElementsByTagName('tr')
  fields = {
      "name": "",
      "address": "",
      "postnummer": "",
      "poststed": "",
      "country": "",
      "tel": "",
      "fax": "",
      "description_nb": "",
      "description_en": "",
      "email": "",
      "homepage": ""
  }
  for tr in datarows
    tds = tr.getElementsByTagName('td')
    key = tds[0].innerHTML.trim()
    if name_mapping.hasOwnProperty(key)
      name_mapping[key](fields, tds[2])
    else
      console.warn("Unknown field:", key)


  productlinks = tables.item(1).getElementsByTagName("a")

  resultArr = [
    {
        "model": "products.provider",
        "pk": thisProviderPk,
        "fields": fields
    },
  ]
  for plink in productlinks
    href = plink.getAttribute('href')
    if href.match(valid_product_link)
      spl = href.split('=')
      pk = parseInt(spl[1], 10)
      resultArr.push({
          "model": "products.productprovider",
          # "pk": 1,
          "fields": {
              "product": pk,
              "provider": thisProviderPk,
          }
      })
    else
      console.warn("Bad link:", plink)

  return resultArr


# ----------------------------

providers_fetch_object = {}
fetched_data = []


parse_provider_json = (responseText) ->
  # context = {
  #       "html": fetch_data(url, "provider_{}".format(pk)),
  #       "type": "singleprovider",
  #       "pk": pk,
  #   }
  resp = JSON.parse(responseText)

  obj = providers_fetch_object[resp.pk]
  unless obj.status is "progress"
    console.warn("Callback for el not in progress!", obj)

  obj.status = 'parsing'
  obj.btn.innerHTML = 'parsing...'
  el = newEl("div", "", resp.html)
  resultArr = singleprovider_parse(el, parseInt(resp.pk, 10))
  fetched_data = fetched_data.concat(resultArr)
  obj.status = 'DONE'
  obj.btn.innerHTML = 'DONE'
  obj.btn.disabled = true
  json_output.innerHTML = JSON.stringify(fetched_data, null, 4)


loadFor = (pk) ->
  obj = providers_fetch_object[pk]
  if obj.status is "ready"
    obj.status = 'progress'
    obj.btn.innerHTML = 'loading...'
    doAjax(obj.url + "?json=true", parse_provider_json)
  else
    console.log("Cannot load, is not ready")

create_provider_line = (res) ->
  e = newEl("div", "", """<a target="_blank" style="width: 22em; display: inline-block;" href="#{res.url}">#{res.title}</a>""")
  btn = newEl("button", "alignbot aligntop", "Load")
  observe(btn, "click", ->
    loadFor(res.pk)
  )
  e.appendChild(btn)
  providers_fetch_object[res.pk] = {
    pk: res.pk,
    status: 'ready',
    data: null,
    btn: btn,
    url: res.url,
    # e: e,
  }
  return e


provider_parse = (html) ->
  providerlinks = html.getElementsByTagName("a")

  resultArr = []

  for plink in providerlinks
    href = plink.getAttribute('href')
    if href.match(valid_provider_link)
      spl = href.split('=')
      pk = parseInt(spl[1], 10)
      resultArr.push({
        pk: pk,
        url: "/import-data/provider/#{pk}",
        title: plink.innerHTML,
      })

  resultArr.sort((a,b) -> a.pk - b.pk)
  # resultArr.sort((a,b) -> a - b)
  console.log("Got ", resultArr.length, " providers")

  # LIMIT = 3
  # count = 1
  # resultArr = []
  # for prov in resultArr
  #   console.log("Fetching detalis for ", prov.title, " pk:", prov.pk)
  #
  #
  #   count += 1
  #   if count > LIMIT break

  frag = document.createDocumentFragment()
  for res in resultArr
    # e = newEl("div", "", """ <a target="_blank" href="#{res.url}">#{res.title}</a> """)
    # btn = newEl("button", "", "Load")
    # e.appendChild(btn)
    el = create_provider_line(res)

    # {
    #   pk: res.pk,
    #   status: 'ready',
    #   btn: btn,
    # }
    frag.appendChild(el)

  elById('result_links').appendChild(frag)
  return resultArr

# ------------------------------

brand_parse = (html) ->
  tables = html.getElementsByTagName("table")
  datarows = tables.item(0).getElementsByTagName('tr')

  nextBrandPk = 1
  nextBrandproviderPk = 1
  # bran =

  brands = []
  productproviders = []

  brandNameMapping = {}
  antidupe = {}

  for tr in datarows
    tds = tr.getElementsByTagName('td')
    if tds.length < 1
      console.info("Skipping tr without td's")
      continue

    name = tds.item(0)?.textContent.trim()
    unless name
      console.warn("Bad tr", tr)
      return
    href = tds.item(2)?.getElementsByTagName('a').item(0).getAttribute('href')

    if brandNameMapping.hasOwnProperty(name)
      brandPk = brandNameMapping[name]
    else
      brandPk = nextBrandPk
      brandNameMapping[name] = brandPk
      brands.push({
          "model": "products.brand",
          "pk": brandPk,
          "fields": {
              "name": name
          }
      })
      nextBrandPk += 1

    if href.match(valid_provider_link)
      spl = href.split('=')
      providerPk = parseInt(spl[1], 10)
      entry = {
        "model": "products.brandprovider",
        "pk": nextBrandproviderPk,
        "fields": {
          "brand": brandPk,
          # "DEBUGname": name,
          "provider": providerPk,

        }
      }
      if antidupe["#{brandPk}_#{providerPk}"]
        console.log("Ignoring duplicate entry for ", name, " at ", providerPk)
      else
        productproviders.push(entry)
        antidupe["#{brandPk}_#{providerPk}"] = true
        nextBrandproviderPk += 1
    else
      console.warn("Invalid provider link": href)

  resultArr = brands.concat(productproviders)
  return resultArr

# {
#     "model": "products.brand",
#     "pk": 1,
#     "fields": {
#         "name": "Aalborg Instruments"
#     }
# },
# {
#     "model": "products.brandprovider",
#     "pk": 1,
#     "fields": {
#         "brand": 1,
#         "provider": 2
#     }
# },
# {
#     "model": "products.productprovider",
#     "pk": 1,
#     "fields": {
#         "product": 1,
#         "provider": 2
#     }
# },

parse_data = ->
  type = htmlContainer.getAttribute("data-type")
  resultArr = switch type
    when 'products'
      product_parse(htmlContainer)
    when 'providers'
      provider_parse(htmlContainer)
    when 'brands'
      brand_parse(htmlContainer)
    when 'singleprovider'
      singleprovider_parse(htmlContainer, window.current_pk)
    else
      console.warn("NOT IMPLEMENTED:", type)
      false
  json_output.innerHTML = JSON.stringify(resultArr, null, 4)

foo_setup = ->
    foo = elByClass("specialpage-import")

    unless foo?.length > 0
        console.log("No specialpage-import")
        return

    json_output = elById('json_output')
    htmlContainer = elById("raw_html")


    btn = elById("start_import")
    observe(btn, "click", parse_data)
    # callback = (responseText) ->
    #   htmlContainer.innerHTML(responseText)
    #
    # doAjax(url, callback)


observe(document, "DOMContentLoaded", (event) ->
    console.info("specialpage-import INIT")
    foo_setup()
)
