window.zeppelin ?= {}

debounce = window.zeppelin.debounce
elByTag = window.zeppelin.elByTag
elById = window.zeppelin.elById
elByClass = window.zeppelin.elByClass
elByQuery = window.zeppelin.elByQuery
observe = window.zeppelin.observe
observeMulti = window.zeppelin.observeMulti
newEl = window.zeppelin.newEl
doAjax = window.zeppelin.doAjax

# ----------------
body = undefined

menuToggler = ->
    menu_wrap = elById("the_menu_wrap")
    return unless menu_wrap

    toggler = elById("menu_toggler")
    observe(toggler, "click", ->
        body.classList.toggle("menu-visible")
        toggler.setAttribute('aria-expanded', body.classList.contains('menu-visible'))
    )

# submenuMap = {}

# make_item_accessible = (item) ->
#   id = item.getAttribute('id')
#   my_links = item.getElementsByTagName("a")
#   # submenuMap[id] = item
#
#   for link in my_links
#     observe(link, 'blur', ->
#       item.classList.remove('accesible-hover')
#       console.debug("Blur", id)
#     )
#     observe(link, 'focus', ->
#       item.classList.add('accesible-hover')
#       console.debug("Focus", id)
#     )
#     # link.submenuId = id
#
#
# accessible_menu = ->
#   submenu_items = elByClass('has_submenu')
#
#   for item in submenu_items
#     make_item_accessible(item)

desk_submenus = []
current_visible = undefined
search_has_focus = false

set_sub_visible_actual = (id) ->
  did_open = current_visible isnt id
  # console.debug("Show submenu ", id)
  for subm in desk_submenus
    if subm.id is id
      subm.el.classList.add("visible")
    else
      subm.el.classList.remove("visible")

  current_visible = id

set_sub_visible = debounce(set_sub_visible_actual, 100)


hide_sub = ->
  # console.debug("Hide all submenus")
  unless search_has_focus
    set_sub_visible(false)

make_item_accessible = (item) ->
  id = item.getAttribute('id')
  opener = item.getElementsByClassName("submenu-opener").item(0)
  submenu = item.getElementsByClassName("submenu").item(0)
  if submenu and item.classList.contains('has_submenu')
    # subClone = newEl("div", 'desktop-sub-menu', item.getElementsByClassName("submenu")[0])
    desk_submenus.push({id: id, el: submenu})

  show = ->
    set_sub_visible(id)

  observe(opener, "mouseover", show)
  observe(submenu, "mouseleave", hide_sub)
  observe(submenu, "mouseover", show)

  my_links = item.getElementsByTagName("a")

  for link in my_links
    observe(link, 'blur', hide_sub)
    observe(link, 'focus', show )

  # Also let the search form keep the submenu open
  my_inputs = item.getElementsByTagName("input")
  for input in my_inputs
    observe(input, 'blur', ->
      search_has_focus = false
      hide_sub()
    )
    observe(input, 'focus', ->
      search_has_focus = true
      show()
    )



accessible_menu = ->
  submenu_items = elByClass('top-menu-item-wrap')

  for item in submenu_items
    make_item_accessible(item)


observe(document, "DOMContentLoaded", (event) ->
    console.info("menu INIT")
    body = elByTag('body')[0]
    menuToggler()
    accessible_menu()
)
