window.zeppelin ?= {}

eachNode = window.zeppelin.eachNode
debounce = window.zeppelin.debounce
elByTag = window.zeppelin.elByTag
elById = window.zeppelin.elById
elByClass = window.zeppelin.elByClass
elByQuery = window.zeppelin.elByQuery
observe = window.zeppelin.observe
observeMulti = window.zeppelin.observeMulti
newEl = window.zeppelin.newEl
doAjax = window.zeppelin.doAjax

# ----------------

# DUMMY DATA STUFF
BASE_WORDS = [
  "orem ipsum dolor",
  "ait amet",
  "consectetur",
  "dipiscing",
  "lit",
  "orbi id",
  "sagittis justo",
  "eaecenas euismod",
  "usto ac quam sagittis",
  "leifend",
  "ram non iaculis",
  "orto",
  "it amet venenatis massa",
  "urabitur posuere",
  "olutpat",
  "fermentum",
  "apaecenas",
  "landit",
  "enim arcu",
  "iquam",
  "est egestas",
  "ivamus nibh",
]
MODULO = BASE_WORDS.length

LETTER_FREQ = [
  {letter: "E", freq: 0.1663,}
  {letter: "N", freq: 0.0814,}
  {letter: "T", freq: 0.0779,}
  {letter: "R", freq: 0.0752,}
  {letter: "A", freq: 0.0605,}
  {letter: "I", freq: 0.0561,}
  {letter: "S", freq: 0.0541,}
  {letter: "D", freq: 0.0489,}
  {letter: "L", freq: 0.0487,}
  {letter: "O", freq: 0.0468,}
  {letter: "G", freq: 0.0404,}
  {letter: "K", freq: 0.0383,}
  {letter: "M", freq: 0.0320,}
  {letter: "V", freq: 0.0267,}
  {letter: "H", freq: 0.0241,}
  {letter: "U", freq: 0.0184,}
  {letter: "P", freq: 0.0169,}
  {letter: "F", freq: 0.0168,}
  {letter: "Å", freq: 0.0148,}
  {letter: "B", freq: 0.0138,}
  {letter: "J", freq: 0.0120,}
  {letter: "Ø", freq: 0.0089,}
  {letter: "Y", freq: 0.0067,}
  {letter: "C", freq: 0.0051,}
  {letter: "W", freq: 0.0031,}
  {letter: "Æ", freq: 0.0026,}
  {letter: "Z", freq: 0.0021,}
  {letter: "X", freq: 0.0004,}
  {letter: "Q", freq: 0.0001,}

  {letter: "0", freq: 0.0001,}
  {letter: "1", freq: 0.0005,}
  {letter: "2", freq: 0.0005,}
  {letter: "3", freq: 0.0005,}
  {letter: "4", freq: 0.0005,}
  {letter: "5", freq: 0.0005,}
  {letter: "6", freq: 0.0005,}
  {letter: "7", freq: 0.0005,}
  {letter: "8", freq: 0.0005,}
  {letter: "9", freq: 0.0005,}
]

randomLetterCount = (freq, balance, total) ->
  return Math.floor(((freq * balance) + (Math.random() * (1-balance))) * total)

getRandomInt = (max) ->
  return Math.floor(Math.random() * Math.floor(max))

get_random_word = (letter, i) ->
  # i = getRandomInt(MODULO)
  return "#{letter}#{BASE_WORDS[i%MODULO]}"

make_wordlist = (caNumberOfWords) ->
  letter_count = []
  for item in LETTER_FREQ
    letter_count.push({letter: item.letter, count: randomLetterCount(item.freq, 0.99, caNumberOfWords)})

  wordlist = []
  pk = 125
  for item in letter_count
    if item.count > 0
      for index in [0..item.count]
        pk += 1
        title = get_random_word(item.letter, index)
        wordlist.push({
          title: title
          pk: pk
          link: "?something=#{pk}"

        })

  return wordlist

# END DUMMY DATA STUFF


localeSortFun = (a, b) ->
  return a.title.localeCompare(b.title, "nb", {sensitivity: "base"})

ALFABET = [
  "0-9",
  "A", "B", "C", "D", "E",
  "F", "G", "H", "I", "J",
  "K", "L", "M", "N", "O",
  "P", "Q", "R", "S", "T",
  "U", "V", "W", "X", "Y",
  "Z", "Æ", "Ø", "Å",
]

CONFIG = window.zeppelin.inpage_config or {}

renderItem = (item) ->
  switch CONFIG.template
    # when 'brand'
    #   el = newEl("div", "brand-provider", """
    #
    #         <h5 class="aligntop">#{item.title}</h5>
    #         <small class="always-link">#{item.link}</small>
    #       </div>
    #     """)
    #   el.href = item.link
    #   return el

    when 'provider'
      el = newEl("a", "tiny-member", """
          <img src="#{item.logo or CONFIG?.def_logo}" width="164" height="123" alt="" />
          <div class="thetext">
            <h5 class="aligntop">#{item.title}</h5>
            <small class="always-link">#{item.link}</small>
          </div>
        """)
      el.href = item.link
      return el
    else
      return newEl("div", "productlist-item", """<a href="#{item.link}">#{item.title}</a>""")



enrichList = (list) ->
  for item in list
    item.searchstr = item.title.toLowerCase()
    item.el = renderItem(item)

renderHeader = (letter) ->
  return newEl("h3", "productlist-header", letter)

insertHeaders = (items) ->
  headers = ALFABET.map((x) -> {title: x, children: [] })
  newlist = items.concat(headers)
  return newlist.sort(localeSortFun)


pagination_prev = undefined
pagination_next = undefined
pagination_page = undefined
pagination_total = undefined
current_page_index = 0
number_of_pages = 1

recalc_pages = (items) ->
  size = CONFIG.el_per_page
  if size < 4 or items.length < size
    number_of_pages = 1
    current_page_index = 0
  else
    old_number = number_of_pages
    number_of_pages = Math.ceil(items.length / size)

    if old_number isnt number_of_pages
      current_page_index = 0
    else
      current_page_index = Math.max(0, Math.min(current_page_index, number_of_pages-1))

  if current_page_index > 0
    eachNode(pagination_prev, (el) -> el.classList.remove('disabled'))
  else
    eachNode(pagination_prev, (el) -> el.classList.add('disabled'))
  if current_page_index < number_of_pages-1
    eachNode(pagination_next, (el) -> el.classList.remove('disabled'))
  else
    eachNode(pagination_next, (el) -> el.classList.add('disabled'))

  eachNode(pagination_page, (el) -> el.innerHTML = 1 + current_page_index)
  eachNode(pagination_total, (el) -> el.innerHTML = number_of_pages)
  # .innerHTML = number_of_pages


prev_page = ->
  old_page_index = current_page_index
  current_page_index -= 1
  recalc_pages(current_items)
  if old_page_index isnt current_page_index
    displayList()
    window.zeppelin.scroll.animateScroll(document.querySelector('#topp'))


next_page = ->
  old_page_index = current_page_index
  current_page_index += 1
  recalc_pages(current_items)
  if old_page_index isnt current_page_index
    displayList()
    window.zeppelin.scroll.animateScroll(document.querySelector('#topp'))



paginate = (items) ->
  # number_of_pages
  items = items.sort(localeSortFun)
  if number_of_pages > 1
    size = CONFIG.el_per_page
    offset = size*current_page_index
    return items.slice(offset, offset+size)
  return items

current_items = null

displayList = () ->
  sourceItems = current_items

  frag = document.createDocumentFragment()

  if CONFIG.el_per_page
    recalc_pages(sourceItems)
    paginated = paginate(sourceItems)
    for item in paginated
      frag.appendChild(item.el)
  else if CONFIG.use_header
    items = insertHeaders(sourceItems)
    mapped = []
    current = {title: "", children: [] }
    for item in items
        if item.children
          mapped.push(current)
          current = item
        else
          current.children.push(item)

    for h in mapped
      if h.children.length > 0
        subfrag = newEl("div", "letter-section")
        subfrag.appendChild(renderHeader(h.title))
        for c in h.children
          subfrag.appendChild(c.el)

        frag.appendChild(subfrag)
  else
    sourceItems = sourceItems.sort(localeSortFun)
    for item in sourceItems
      frag.appendChild(item.el)

  filterable_list_wrap.innerHTML = ""
  filterable_list_wrap.appendChild(frag)
  # return frag
  # .cloneNode(true)

myItemList = null
filterable_list_wrap = null
inpage_search_input = null

refilter = () ->
  keywords = inpage_search_input.value
  items = myItemList
  if keywords?.length > 0
    kw = keywords.toLowerCase()
    items = myItemList.filter((i) -> i.searchstr.includes(kw))

  current_items = items
  displayList()
  # filterable_list_wrap.innerHTML = ""
  # filterable_list_wrap.appendChild(displayList(items))

test_area_stuff = ->
    filterable_list_wrap = elById("filterable_list_wrap")

    unless filterable_list_wrap
        console.log("No filterable_list_wrap")
        return
    inpage_search_input = elById("inpage_search_input")

    pagination_prev = elByClass("pagination_prev")
    pagination_next = elByClass("pagination_next")
    pagination_page = elByClass("pagination_page")
    pagination_total = elByClass("pagination_total")

    if pagination_prev and pagination_next
      observeMulti(pagination_prev, "click", prev_page)
      observeMulti(pagination_next, "click", next_page)

    myItemList = JSON.parse(elById('myItemList')?.textContent or "[]");
    if myItemList.length > 1
      enrichList(myItemList)
    else
      premadeItems = filterable_list_wrap.getElementsByClassName("fil-list-item")
      myItemList = []
      for item in premadeItems
        myItemList.push({
          searchstr: item.getAttribute('data-searchstr'),
          title: item.getAttribute('data-title'),
          el: item,
        })

    # myItemList = window.zeppelin.myItemList or make_wordlist(600)
    current_items = myItemList
    displayList()
    refilt = debounce(refilter, 250)
    observe(inpage_search_input, 'keydown', refilt)
    observe(inpage_search_input, 'search', refilt)

    # test_area.innerHTML = JSON.stringify(make_wordlist(100), null, '    ')


observe(document, "DOMContentLoaded", (event) ->
    console.info("test_area_stuff INIT")
    test_area_stuff()
)
